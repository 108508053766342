import { useEffect, useRef, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import { deployUpdates, getDeploymentStatus } from 'src/services/CmsService';
import { fDateTime } from 'src/utils/format-time';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

export default function DeployButton() {

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [conclusion, setConclusion] = useState(null);
  const [data, setData] = useState(null);
  const confirm = useBoolean();

  const smDown = useResponsive('down', 'sm');

  // Ref for the interval so we can clear it when needed
  const intervalRef = useRef(null);

  const refresh = async () => {
    setLoading(true);
    try {
      const response = await getDeploymentStatus();
      console.log('getDeploymentStatus resp', response);
      setStatus(response?.data?.status);
      setConclusion(response.data.conclusion);
      setData(response.data);

      // If status is completed, stop timer
      if (response.data.status === 'completed' && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      // if status is queued or in_progress, start timer
      if (response.data.status === 'queued' || response.data.status === 'in_progress') {
        if (!intervalRef.current) {
          intervalRef.current = setInterval(refresh, 10000);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    refresh();

    // Ensure the interval is cleared when the component is unmounted
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickDeploy = async () => {
    setStatus("in_progress");
    setConclusion(null);
    deployUpdates();
    confirm.onFalse();

    // Start a timer of 10 seconds and call refresh on every loop
    if (!intervalRef.current) {
      intervalRef.current = setInterval(refresh, 10000);
    }
  }

  return (<>
    <Stack
      direction='row'
      gap={2}
      alignItems='center'
      sx={{
      }}
    >
      {conclusion === 'success' && <Typography variant={smDown ? 'caption' : 'body2'}>Last deployement: {fDateTime(data.run_started_at)}</Typography>}
      {(status === 'in_progress' || status === 'queued') && <Typography variant={smDown ? 'caption' : 'body2'}>Deploying...</Typography>}
      {conclusion === 'failure' && <Typography variant={smDown ? 'caption' : 'body2'} color='error'>Deployment failed ! <Box component='span' color='text.primary'>Please try again :)</Box></Typography>}
      <LoadingButton size='large' variant='contained' color='primary'
        disabled={(status !== 'completed')}
        loading={!conclusion && (status === 'queued' || status === 'in_progress' || loading)}
        onClick={() => {
          confirm.onTrue();
        }}
        endIcon={<Iconify icon='basil:upload-solid' />}>
        Deploy
      </LoadingButton>
    </Stack>
    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Confirm deployment to production"
      content={<Stack gap={1}>
        <Typography>By clicking Deploy Now, you agree to perform the following actions:</Typography>
        <Typography variant='body2'>
          <ol>
            <li> All latest data will be irreversably published to the website.</li>
            <br />
            <li>The deployment will take about 90 seconds. It will not affect the current website.</li>
            <br />
            <li>You will need to wait until this deployment to finish before attempting another.</li>
            <br />
            <li>It is possible to have deployments fail due to technical reasons. Don&apos;t worry, no data shall be lost. You can always re-attempt deployment.</li>
          </ol>
        </Typography>
      </Stack>}
      action={
        <Button variant="contained" color="primary" onClick={handleClickDeploy}>
          Yes. Deploy Now.
        </Button>
      }
    /></>
  );
}
