import axios, { endpoints, fetcher } from 'src/utils/network.phonepe';

export const getPageDataFile = async (fileName) => {
  try {
    fileName = encodeURI(fileName);
    const response = await axios.get(`${endpoints.cms.pageJson}/${fileName}`);
    const data = response.data;
    return data;

  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

export const updatePageJson = async ({ fileName, fileJson }) => {
  try {
    const response = await axios.post(endpoints.cms.pageJson, { fileName, fileJson });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const deployUpdates = async () => {
  try {
    const response = await axios.post(endpoints.cms.deploy);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getDeploymentStatus = async () => {
  try {
    const response = await axios.post(endpoints.cms.status);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const formUploadFileSingle = async (file, key, setValue) => {
  // Check if the file exists
  if (!file) {
    return;
  }
  // Prepare the FormData
  const formData = new FormData();
  formData.append('images', file); // images are uploaded to 'images'

  try {
    // Make the upload request to the backend
    const response = await axios.post(endpoints.cms.uploads, formData);

    // Parse the JSON response to get the public URL
    const data = response.data;
    const publicUrl = data.url;

    console.log('Uploaded file public URL:', publicUrl);

    // Set the value using the public URL
    setValue(key, publicUrl, { shouldValidate: true });

  } catch (error) {
    console.error('Error uploading the file:', error);
  }
}
